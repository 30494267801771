import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/kani-marouf'

import background from '../../assets/images/kani-marouf/background.png'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
