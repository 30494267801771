import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/kani-marouf/background.png'
import still from '../../../assets/images/kani-marouf/still.png'


import img1 from '../../../assets/images/kani-marouf/1.jpg' 
import img2 from '../../../assets/images/kani-marouf/2.jpg' 
import img3 from '../../../assets/images/kani-marouf/3.jpg' 
import img4 from '../../../assets/images/kani-marouf/4.jpg' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'Kurdistan 1920-96',
    image: img1
  },
  {
    label: 'Great and Small Kurdistan',
    image: img2
  },
  {
    label: 'The great Kurdistan',
    image: img3
  },
  {
    label: 'Iraqi Kurdistan',
    image: img4
  },
];

export default function Album(props) {

  return (
    <>

      <Header
        name='Kani Marouf'
        title='Images of a Favour'
        date='11 June - 15 July 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
        The film is shot in Kirkuk and Baghdad in the aftermath of the referendum for the independence of Kurdistan held in September 2017. Although successful, the Iraqi authorities condemned the referendum as unconstitutional and in October 2017 invaded the Kurdish province of Kirkuk.
        </Typography>
        <Typography variant='subtitle1'>
        Unable to reach the region, Kani Marouf asked her Kirkuk-based friend Shaho Abdulkadr to send footage from his city during the invasion. So he filmed Kirkuk, in juxtaposition to Baghdad. Constructing her film using this opaque quality-footage, the filmmaker also speculates on the difficulties of representing such scenario through images.
        </Typography>
      </Text>

      <Film 
        url="559940265?h=a2b5d1638b"
        title="Kani Marouf, Images of a Favour 2018, 23 min. 52”"
        titleShort="Kani Marouf - Images of a Favour"
      />

      <Essay 
        title="Vilém Flusser"
        subtitle="Exile and Creativity"
        url="/articles/villem-flusser-exile-and-creativity"
      >
      <Typography
        paragraph variant='subtitle1'
      >
        This essay will explore neither the existential nor the religious connotations of the concept of the term exile. However, we should keep in the back of our mind the Christian story of man’s expulsion from Paradise and his entrance into the world, the Jewish mystic’s story of the exile of divine spirit in the world, and the existentialist story of man as a stranger in the world. All of these stories should be kept in the back of our mind without being verbalized. For the intention here is to interpret the exile situation as a challenge to creative activity.
      </Typography>
      <Typography 
        paragraph variant='subtitle1'
      >
        This is the proposed hypothesis: The expelled has been torn out of his customary surroundings (or else he has done it himself. Habit is a blanket that covers up the facts of the case. In familiar surroundings, change is recognized, but not permanence.
      </Typography>
      </Essay>



      <Soundtrack 
        title="The Struggle of Representation"
        subtitle="Kani Marouf in conversation with Felice Moramarco"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/kani-podcast-in-coversation-with-felice-moramarco"
      />

      <Gallery 
        slides={slides}
        title='Laura Canali, in Limes ‘Il mto Curdo’ July 2017. Courtesy Laura Canali and limesonline.com'
      />
      
      <Footer />

    </>
  );
}
